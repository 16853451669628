@import '../../styles//mixins';

.section {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: $black;
	color: $white;

	&.full-height {
		@include dynamicVH(100);
	}

	&.center {
		justify-content: center;
	}

	&.mounting {
		position: absolute;
		top: 0;
		left: 0;
	}

	&.white {
		background-color: $white;
		color: $black;
	}

	.container {
		position: relative;
		width: 100%;
		height: 100%;
		max-width: $max-container-width;
		padding-left: var(--borderMargin-horizontal);
		padding-right: var(--borderMargin-horizontal);
		padding-top: calc(var(--borderMargin-vertical) + var(--verticalSpacer));
		padding-bottom: calc(var(--borderMargin-vertical) + var(--verticalSpacer));
	}
}
