@import '../../styles/mixins';

.menu-wrapper {
	width: 100%;
	max-width: $max-container-width;
	padding-left: var(--borderMargin-horizontal);
	padding-right: var(--borderMargin-horizontal);
	position: absolute;
	z-index: 2;
	top: 0;
	margin-top: var(--borderMargin-vertical);
	@include centerHorizontal();
	display: flex;
	justify-content: space-between;
	transition: color 500ms ease-out;
	color: $white;

	&.white {
		color: $black;
	}

	@include breakpoint(small) {
		flex-direction: row-reverse;
	}

	.menu-buttons-wrapper {
		@include breakpoint(small) {
			display: flex;
			flex-direction: row;
		}

		.menu-button {
			cursor: pointer;
			font-weight: normal;
			text-transform: uppercase;

			@include breakpoint(small) {
				margin-left: 1rem;
			}

			&.left {
				position: absolute;
				left: var(--borderMargin-horizontal);

				@include breakpoint(small) {
					position: relative;
					left: auto;
				}
			}

			&.right {
				position: absolute;
				right: var(--borderMargin-horizontal);

				@include breakpoint(small) {
					position: relative;
					right: auto;
				}
			}

			.menu-button-text {
				&:hover {
					font-weight: 700;
				}

				&.active {
					font-style: italic;
					font-weight: 700;
				}
			}
		}
	}

	.menu-logo-wrapper {
		cursor: pointer;
		position: absolute;
		@include centerHorizontal();

		@include breakpoint(small) {
			position: relative;
			left: auto;
			transform: translateX(0%);
		}
	}
}
