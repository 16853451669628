// All Sass Imports
@import './styles/base';
@import './styles/fonts';
@import './styles/mixins';
@import './styles/typography';
@import './styles/variables';
@import './styles/dynamics';
@import './styles/animations';

.app {
	top: 0;
	width: 100%;
}
