// Useful Mixins
@import './variables';

@mixin buttonInteractions() {
	transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);

	&:hover {
		background-color: rgba($white, 0.15);
	}

	&:active {
		background-color: rgba($white, 0.35);
	}
}
