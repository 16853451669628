// Global Variables

// Breakpoint sizes (fits iPhone 12 landscape)
$screen-small: 845px;
$screen-medium: 846px;
$screen-large: 1080px;

// For container width restricted site designs
$max-container-width: 1080px;

// Screen sizes for fluid responsive method
$min-window: 846px;
$max_window: 1080px;

// Colors
$black: #0d0d0d;
$white: #fcfcfd;
$transparent: rgba(0, 0, 0, 0);
