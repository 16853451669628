// Global Dynamic Variables
@import './mixins';

:root {
	--vh: 1vh;
	--vw: 1vw;

	@include breakpoint(small) {
		--borderMargin-horizontal: 5vw;
		--borderMargin-vertical: 24px;
		--innerMargin: 5vw;
		--verticalSpacer: 72px;
	}

	@include breakpoint(medium) {
		--borderMargin-horizontal: 8vw;
		--borderMargin-vertical: 3.2vw;
		--innerMargin: 8vw;
		--verticalSpacer: 96px;
	}

	@include breakpoint(large) {
		--borderMargin-horizontal: 8vw;
		--borderMargin-vertical: 2.8vw;
		--innerMargin: 8vw;
		--verticalSpacer: 128px;
	}

	--innerMargin_025x: calc(var(--innerMargin) / 4);
	--innerMargin_05x: calc(var(--innerMargin) / 2);
	--innerMargin_2x: calc(var(--innerMargin) * 2);
	--innerMargin_3x: calc(var(--innerMargin) * 3);
	--innerMargin_4x: calc(var(--innerMargin) * 4);
	--innerMargin_8x: calc(var(--innerMargin) * 8);

	--verticalSpacer_025x: calc(var(--verticalSpacer) / 4);
	--verticalSpacer_05x: calc(var(--verticalSpacer) / 2);
	--verticalSpacer_2x: calc(var(--verticalSpacer) * 2);
	--verticalSpacer_3x: calc(var(--verticalSpacer) * 3);
	--verticalSpacer_4x: calc(var(--verticalSpacer) * 4);
	--verticalSpacer_4x: calc(var(--verticalSpacer) * 8);
}
