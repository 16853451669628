// Font Declarations
@font-face {
	font-family: 'iA Writer Quattro';
	font-weight: 400;
	font-style: normal;
	font-display: block;
	src: url('./fonts/iAWriterQuattroS-Regular.woff2') format('woff2'),
		url('./fonts/iAWriterQuattroS-Regular.woff') format('woff');
}

@font-face {
	font-family: 'iA Writer Quattro';
	font-weight: 700;
	font-style: normal;
	font-display: block;
	src: url('./fonts/iAWriterQuattroS-Bold.woff2') format('woff2'),
		url('./fonts/iAWriterQuattroS-Bold.woff') format('woff');
}
