@import '../../styles/mixins';

.home-section {
	background-color: $white;
	color: $black;
}

.home-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	.home-image-wrapper {
		width: 50%;

		.home-image {
			width: 100%;
		}
	}
}
