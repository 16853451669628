@import '../../styles/mixins';
@import '../../styles/animations';

.form-input-container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 1rem;

	.form-input-field {
		width: 100%;
		margin: 0 0.5rem;

		font-size: 1rem;
		height: 2.5rem;
		background-color: transparent;
		border: 1px solid $white;
		outline: none;
		color: $white;
		text-align: center;

		&.textarea {
			min-height: 2rem;
		}

		&::placeholder {
			opacity: 1;
			text-transform: uppercase;
		}
	}

	.increment-button {
		cursor: pointer;
		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		border: 1px solid $white;

		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		@include buttonInteractions();

		.increment-button-icon {
			width: 75%;
		}
	}

	.form-error-message-wrapper {
		position: absolute;
		bottom: 0.2rem;

		.form-error-message {
			font-size: 0.6rem;
			line-height: 1;
			margin: 0;
			font-weight: 600;
			text-transform: uppercase;
			color: $white;
			opacity: 0.5;
		}
	}
}
