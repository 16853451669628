// Useful Mixins
@import './variables';
@import './functions';

// center horizontally (absolutes)
@mixin centerHorizontal() {
	left: 50%;
	transform: translateX(-50%);
}

// center horizontally (absolutes)
@mixin centerVertical() {
	top: 50%;
	transform: translateY(-50%);
}

// dynamic VH for mobile screen size issues
@mixin dynamicVH($quantity) {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * #{$quantity});
}

// dynamic VW for scrollbar issues
@mixin dynamicVW($quantity) {
	width: calc(var(--vw) * #{$quantity});
}

@mixin buttonHover() {
	transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);

	&:hover {
		background-color: rgba($white, 0.42);
	}
}

// Breakpoints
@mixin breakpoint($class) {
	@if $class == small {
		@media (max-width: $screen-small) {
			@content;
		}
	} @else if $class == medium {
		@media (min-width: $screen-medium) {
			@content;
		}
	} @else if $class == large {
		@media (min-width: $screen-large) {
			@content;
		}
	}
}

// Interpolate a value over a set range between two breakpoints: https://codepen.io/MadeByMike/pen/vNrvdZ
@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value) {
	& {
		@each $property in $properties {
			#{$property}: $min-value;
		}

		@media screen and (min-width: $min-screen) {
			@each $property in $properties {
				#{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
			}
		}

		@media screen and (min-width: $max-screen) {
			@each $property in $properties {
				#{$property}: $max-value;
			}
		}
	}
}
