// Typography • Text Styles
@import './mixins';

// Typography Variables
$min-rem: 16px;
$max-rem: 18px;

// Ratios for headings (applies easings throughout the breakpoints)
// H1
$min-h1: $min-rem * 1.5;
$max-h1: $max-rem * 2;
// H2
$min-h2: $min-rem * 1.2;
$max-h2: $max-rem * 1.2;

// Globals
html {
	font-family: 'iA Writer Quattro S', Arial, Helvetica, sans-serif;
	letter-spacing: 0.02ch;
	font-feature-settings: 'ss04' on; // dotless zeros
	@include interpolate(font-size, $min-window, $max-window, $min-rem, $max-rem);
}

a {
	pointer-events: auto;
	color: inherit;
	margin: 0px;
	padding: 0px;

	&:hover {
		text-decoration: underline;
	}

	&.button-text {
		cursor: pointer;
		font-size: 2rem;
		line-height: 0.8;
		font-weight: bold;
		text-transform: uppercase;
	}
}

h1 {
	@include interpolate(font-size, $min-window, $max-window, $min-h1, $max-h1);
	font-style: normal;
	font-weight: 700;
	line-height: 1.2em;
	margin: 0px;
	padding: 0px;
	text-transform: uppercase;
}

h2 {
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.2;
	margin-bottom: 1rem;
	padding: 0px;
}

h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 0.9;
	margin: 0px;
	padding: 0px;
	margin-bottom: 2rem;
}

p {
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.5;
	margin: 0px;
	padding: 0px;
	margin-bottom: 1.5rem;

	&.capitalized {
		font-weight: bold;
		font-size: 0.8rem;
		line-height: 1;
		text-transform: uppercase;
	}
}
