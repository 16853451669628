@import '../../styles/mixins';

.minter-form {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;

	@include breakpoint(small) {
		width: 100%;
	}

	.minter-form-line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&.center {
			justify-content: center;
		}
	}
}

.minter-form-field-label {
	font-size: 0.8rem;
	margin: 1rem 0;
}
