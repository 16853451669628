@import '../../styles/mixins';

.about-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	min-height: 100%;

	.about-block {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
