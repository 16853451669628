// Basic Styles • Overrides
@import './mixins';
@import './animations';

// Box sizing
html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	margin: 0;
}

// Links
a {
	text-decoration: none;
}

button {
	cursor: pointer;
	width: 100%;

	font-size: 1rem;
	font-weight: normal;
	letter-spacing: 0.2ch;
	text-transform: uppercase;

	height: 2.5rem;
	background-color: transparent;
	@include buttonInteractions();

	border: 1px solid $white;
	outline: none;
	color: $white;
	text-align: center;
}

/* width */
::-webkit-scrollbar {
	width: 1px;
	height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: white;
	border-radius: 0px;
}
