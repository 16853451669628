@import '../../styles/mixins';

.background-image-wrapper {
	height: 100vh;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	opacity: 0.36;

	.background-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
