@import '../../styles/mixins';

.minter-form-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;
}
